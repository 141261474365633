import React, { Component } from 'react';
import PropTypes from 'proptypes';

const styles = {
  main: {
    fontWeight: 'bold',
    fontSize: '2.25rem',
    lineHeight: '2.5rem',
    margin: 0,
    padding: 0,
    color: '#212121',
    textTransform: 'uppercase',
    marginBottom: '1rem',
  },
  sub: {
    color: '#616161',
    fontSize: '1rem',
    lineHeight: '1rem',
    margin: 0,
    padding: 0,
    textTransform: 'uppercase',
  },
};

export default class SectionLabel extends Component {
  render() {
    return (
      <div>
        <h3 style={styles.sub}>{this.props.sub}</h3>
        <h2 style={styles.main}>{this.props.main}</h2>
      </div>
    );
  }
}

SectionLabel.propTypes = {
  main: PropTypes.string.isRequired,
  sub: PropTypes.string,
};
