/* eslint-disable */
import React, { Component } from 'react';
import Scroll from 'react-scroll';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Snackbar } from '@mui/material'
import { Header } from './header';
import Title from './title/title';
import About from './about/about';
import Team from './team/team';
import Products from './products/products';
// import { Locations } from './locations/locations';
import Footer from './footer';
import Quote from './quote/quote';
import Policy from './quote/policy';
import { sendRequestQuote, sendRequestPolicyChange } from './api';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-85598084-1');

const Link = Scroll.Link;
const Element = Scroll.Element;
const Events = Scroll.Events;
const scroll = Scroll.animateScroll;
const scrollSpy = Scroll.scrollSpy;

const established = '1971-01-01';

const services = [
  'Auto',
  'Home',
  'Life / Health',
  'Business',
  'Bonds',
  'SR-22',
  'Special/Events',
  '...more',
];

const TooeleMarker = {
  key: 'tooele',
  position: { 
    lat: 40.5412987,
    lng: -112.2987347
  },
  title: 'Tooele Branch',
  tel: '435-882-1216',
};
const GrantsvilleMarker = {
  key: 'grantsville',
  position: { 
    lat: 40.600572,
    lng: -112.458305 
  },
  title: 'Grantsville Branch',
  tel: '435-884-6913',
};
const locations = [TooeleMarker, GrantsvilleMarker];

export class Main extends Component {
  constructor() {
    super();

    this.state = {
      requestQuote: false,
      quote: {},
      requestPolicy: false,
      policy: {},
      toastMessage: '',
      showToast: false,
    };
  }

  componentDidMount() {
    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  handleShowQuote = () => {
    ReactGA.event({
      category: 'Forms',
      action: 'Opened Request Quote Form',
    });
    this.setState({ requestQuote: true });
  }

  handleShowPolicy = () => {
    ReactGA.event({
      category: 'Forms',
      action: 'Opened Policy Change Form',
    });
    this.setState({ requestPolicy: true });
  }

  handleCloseQuote = () => {
    this.setState({ requestQuote: false });
  }

  handleQuoteFormChange = (form) => {
    this.setState({ quote: form });
  }

  handlePolicyChangeFormChange = (form) => {
    this.setState({ policy: form });
  }

  handleSendQuote = () => {
    ReactGA.event({
      category: 'Forms',
      action: 'Sent Request Quote Form',
    });
    const email = {
      service: this.state.quote.service,
      contactName: this.state.quote.name,
      contactMethod: this.state.quote.method,
      contactEmail: this.state.quote.email,
      contactPhone: this.state.quote.phone,
      message: this.state.quote.message,
    };

    sendRequestQuote(email)
     .then((response) => {
       const message = response.data.message;
       this.setState({ showToast: true,
         toastMessage: message,
         requestQuote: false,
         quote: {} });
     })
     .catch((error) => {
       ReactGA.event({
         category: 'Error',
         action: 'Error Sending Quote Form',
       });
       const message = error.data.message;
       this.setState({ showToast: true,
         toastMessage: error });
     });
  }

  handleClosePolicy = () => {
    this.setState({ requestPolicy: false });
  }

  handleSendPolicy = () => {
    ReactGA.event({
      category: 'Forms',
      action: 'Sent Policy Change Form',
    });
    const email = {
      policy: this.state.policy.policy,
      contactName: this.state.policy.name,
      contactMethod: this.state.policy.method,
      contactEmail: this.state.policy.email,
      contactPhone: this.state.policy.phone,
      message: this.state.policy.message,
    };

    sendRequestPolicyChange(email)
     .then((response) => {
       const message = response.data.message;
       this.setState({ showToast: true,
         toastMessage: message,
         requestPolicy: false,
         policy: {} });
     })
     .catch((error) => {
       ReactGA.event({
         category: 'Error',
         action: 'Error Sending Policy Change Form',
       });
       const message = error.data.message;
       this.setState({ showToast: true,
         toastMessage: error });
     });
  }

  handleRequestCloseToast = () => {
    this.setState({ showToast: false,
      toastMessage: '' });
  }

  render() {
    return (
      <div>
        <Dialog
          fullWidth
          maxWidth="md"
          autoScrollBodyContent
          open={this.state.requestQuote}
          >
          <DialogTitle>Request a Quote</DialogTitle>
          <DialogContent>
            <Quote services={services} onFieldChange={this.handleQuoteFormChange} />
          </DialogContent>
          <DialogActions>
            <Button
              label="Cancel"
              onClick={this.handleCloseQuote}
              >Cancel
            </Button>
            <Button
              label="Submit"
              primary
              onClick={this.handleSendQuote}
              >Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="md"
          autoScrollBodyContent
          open={this.state.requestPolicy}
          >
          <DialogTitle>Request Policy Change</DialogTitle>
          <DialogContent>
            <Policy onFieldChange={this.handlePolicyChangeFormChange.bind(this)} />
          </DialogContent>
          <DialogActions>
            <Button
              label="Cancel"
              onClick={this.handleClosePolicy}
              >Cancel
            </Button>
            <Button
              label="Submit"
              primary
              onClick={this.handleSendPolicy}
              >Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={this.state.showToast}
          message={this.state.toastMessage}
          autoHideDuration={3000}
          onRequestClose={this.handleRequestCloseToast.bind(this)}
          />
        <Header
          handleShowQuote={this.handleShowQuote}
          handleShowPolicy={this.handleShowPolicy}
          />
        <main>
          <Title services={services} />
          <Element name="about" className="element">
            <About established={established} />
          </Element>
          <Element name="partners" className="element">
            <Products />
          </Element>
          <Element name="team" className="element">
            <Team />
          </Element>
          <Element name="info" className="element">
            <div
              style={{ background: '#1f1f1f',
                height: '10px' }}
              />
            {/* <Locations markers={locations} /> */}
          </Element>
        </main>
        <Footer services={services} />
      </div>
    );
  }
}
