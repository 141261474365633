/* eslint func-style:1, require-jsdoc:1*/
/* eslint-disable */
import axios from 'axios';

const uri = `/.netlify/functions/sendmail`;

export function sendAgentEmail(form) {
  const email = {
    type: "contact_agent",
    to: form.to,
    agentName: form.agentName,
    contactName: form.contactName,
    contactMethod: form.contactMethod,
    contactEmail: form.contactEmail,
    contactPhone: form.contactPhone,
    message: form.message,
  };

  return axios.post(uri, email);
}

export function sendRequestQuote(form) {
  const email = {
    type: "quote_request",
    to: "mmcurry@curins.com",
    service: form.service,
    contactName: form.contactName,
    contactMethod: form.contactMethod,
    contactEmail: form.contactEmail,
    contactPhone: form.contactPhone,
    message: form.message,
  };

  return axios.post(uri, email);
}

export function sendRequestPolicyChange(form) {
  const email = {
    to: "mmcurry@curins.com",
    type: "policy_change",
    policy: form.policy,
    contactName: form.contactName,
    contactMethod: form.contactMethod,
    contactEmail: form.contactEmail,
    contactPhone: form.contactPhone,
    message: form.message,
  };

  return axios.post(uri, email);
}
