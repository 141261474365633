import React, { Component } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles'
//import { orange500 } from '@mui/material/styles/colors';
import { Main } from './main';

const theme = createTheme({
  palette: {
    primary: {
      main: "#26c6da"
    },
    secondary: {
      main: "#ff9800",
    },
    accent1Color: "red",
  },
});

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Main/>
      </ThemeProvider>
    );
  }
}

export default App;
