/* eslint-disable */
import React from 'react';
import PropTypes from 'proptypes'
import { Grid, Tabs, Tab, Paper } from '@mui/material';
import bg from "./images/main.jpg"
//import { Quote } from '../quote/quote';
//import { orange500, cyan400, cyan700 } from 'material-ui/styles/colors';

const styles = {
  bg: {
    background: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center right',
    height: '33rem',
  },
  infoBox: {
    marginTop: '-.5rem',
    background: '#fff',
  },
  quoteTabContent: {
    padding: '0 10px',
  },
  serviceTabContent: {

  },
  title: {
    backgroundColor: '#ff9800',
    textTransform: 'uppercase',
    color: "#fff",
    textAlign: 'center',
    height: 48,
    padding: '.75rem',
  },
  serviceList: {
    backgroundColor: '#26c6da',
    fontSize: '1.25rem',
    color: 'white',
  },
  serviceItem: {
    boxSizing: 'border-box',
    padding: '0px',
    margin: '0px',
    width: '50%',
    height: '120px',
    float: 'left',
    borderTop: `1px solid #0097a7`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  serviceItemOdd: {
    boxSizing: 'border-box',
    padding: '0px',
    margin: '0px',
    width: '50%',
    height: '120px',
    float: 'left',
    borderRight: `1px solid  #0097a7`,
    borderTop: `1px solid  #0097a7`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
};

export default function Title({ services }) {
  return (
    <Grid container spacing={0} style={styles.bg}>
      <Grid item xs={1}/>
      <Grid item xs={4}>
        <Paper zDepth={2} style={styles.infoBox}>
          <Grid container spacing={0} justifyContent="flex-start" style={styles.serviceList}>
            <Grid item xs={12} style={styles.title}>Services</Grid>
            {services.map((service, index) => (
              <Grid key={service} item xs={6} style={styles[(index % 2) ? 'serviceItem' : 'serviceItemOdd']}>
                {service}
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
  
}
