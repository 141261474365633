/* eslint-disable */
import React, { Component } from 'react';
import Scroll from 'react-scroll';
import { Grid, Button } from '@mui/material';
//import { cyan500, orange500 } from 'material-ui/styles/colors';

const Link = Scroll.Link;
const Element = Scroll.Element;
const Events = Scroll.Events;
const scroll = Scroll.animateScroll;
const scrollSpy = Scroll.scrollSpy;

const styles = {
  header: {
    backgroundColor: '#fff',
  },
  contact: {
    backgroundColor: '#1f1f1f',
    textAlign: 'right',
    color: '#fff',
    fontSize: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  contactItem: {
    paddingRight: '10px',
    color: 'antiquewhite',
  },
  topInfo: {
    color: 'white',
    textDecoration: 'none',
  },
  divider: {
    margin: '0 10px',
    color: '#666',
  },
  titleSection: {
    minHeight: '120px',
    alignItems: 'center',
  },
  title: {
    fontSize: '2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  menu: {
    fontSize: '1.2rem',
    color: '#4e4e4e',
    textTransform: 'uppercase',
  },
  menuList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    clear: 'both',
  },
  menuItem: {
    float: 'left',
    marginRight: '0em',
    cursor: 'pointer',
  },
};

export class Header extends Component {
  render() {
    return (
      <header style={styles.header}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div style={styles.contact}>
              <div style={styles.contactItem}>
                Tooele Office: <a style={styles.topInfo} href="tel:435-882-1216">(435) 882-1216</a>
              </div>
              <div style={styles.contactItem}>
                Grantsville Office: <a style={styles.topInfo} href="tel:435-884-6913">(435) 884-6913</a>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="space-between" style={styles.titleSection}>
          <Grid xs={1}/>
          <Grid xs={12} sm={6} mdOffset={1} md={5}>
            <div style={styles.title}>
              Curry Insurance Agency Inc.
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <div style={styles.menu}>
              <ul style={styles.menuList}>
                <li style={styles.menuItem}>
                  <Button color="primary" onClick={this.props.handleShowQuote}>Request a Quote</Button>
                </li>
                <li style={styles.menuItem}>
                  <Button color="primary" onClick={this.props.handleShowPolicy}>Request Policy Change</Button>
                </li>
              </ul>
              <ul style={styles.menuList}>
                <li style={styles.menuItem}>
                  <Link activeClass="active" to="about" smooth>
                    <Button variant="flat">About</Button>
                  </Link>
                </li>
                <li style={styles.menuItem}>
                  <Link activeClass="active" to="partners" smooth>
                    <Button variant="flat">Partners</Button>
                  </Link>
                </li>
                <li style={styles.menuItem}>
                  <Link activeClass="active" to="team" smooth>
                    <Button variant="flat">Team</Button>
                  </Link>
                </li>
                <li style={styles.menuItem}>
                  <Link activeClass="active" to="info" smooth>
                    <Button variant="flat">Locations</Button>
                  </Link>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </header>
    );
  }
}
