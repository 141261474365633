import React, { Component } from 'react';
import {TextField, Grid, MenuItem} from '@mui/material';

export default class Contact extends Component {

  state = {
    name: null,
    email: null,
    phone: null,
    method: 'Phone',
    message: null,
  };

  handleChange = (field) => (event) => {
    this.setState({ [field]: event.target.value }, () => {
      this.props.onFieldChange(this.state);
    });
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Name"
            variant="standard"
            fullWidth
            onChange={this.handleChange('name')}
            />
       </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            variant="standard"
            fullWidth
            onChange={this.handleChange('email')}
            />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Phone Number"
            variant="standard"
            fullWidth
            onChange={this.handleChange('phone')}
            />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            label="Preferred Contact Method"
            variant="standard"
            fullWidth
            value={this.state.method}
            onChange={this.handleChange('method')}
            >

            <MenuItem value={'Phone'}>Phone</MenuItem>
            <MenuItem value={'Email'}>Email</MenuItem>
          </TextField>
          </Grid>
        <Grid item xs={12}>
          <TextField
            label="Message"
            variant="standard"
            fullWidth
            multiLine
            rowsMax={2}
            onChange={this.handleChange('message')}
            />
        </Grid>
      </Grid>
    );
  }
}
