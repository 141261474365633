/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'proptypes';
import {Paper, IconButton } from '@mui/material';
import { Phone, Email } from '@mui/icons-material'

const styles = {
  person: {
    border: '1px solid #ccc',
    height: '23rem',
    width: '17rem',
    margin: '1rem',
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
  },
  figure: {
    margin: 0,
    padding: 0,
  },
  photo: {
    width: '100%',
    height: '14rem',
  },
  h3: {
    fontSize: '1.5rem',
    margin: '0 0 0 0',
  },
  h4: {
    fontWeight: 'normal',
    fontSize: '1rem',
    margin: '0 0 1rem 0',
    color: '#898e8f',
    textTransform: 'lowercase',
  },
  content: {
    margin: '0',
    padding: '1rem',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
};

export default class Person extends Component {
  render() {
    return (
      <Paper zDepth={1} style={styles.person}>
        <figure style={styles.figure}>
          <img style={styles.photo} src={this.props.person.photo} />
        </figure>
        <div style={styles.content}>
          <h3 style={styles.h3}>
            {this.props.person.name}
          </h3>
          <h4 style={styles.h4}>{this.props.person.branch}</h4>
          <div style={styles.buttons}>
            <IconButton
              color="secondary"
              href={`tel:${this.props.person.phone}`}
              >
              <Phone/>
            </IconButton>
            <IconButton
              color="secondary"
              onClick={this.props.onShowMessageForm}
              >
              <Email/>
            </IconButton>
          </div>
        </div>
      </Paper>
    );
  }
}

Person.propTypes = {
  person: PropTypes.object.isRequired,
};
