import React from 'react';
import moment from 'moment';
import { Grid } from '@mui/material';

const styles = {
  about: {
    textAlign: 'right',
    marginTop: '30px',
    marginBottom: '60px',
  },
  main: {
    //background: 'url("app/about/images/group2019.jpg")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '85%',
    height: '375px',
    margin: '1rem',
  },
  excellence: {
    display: 'none',
    border: '6px solid white',
    background: '#35c5ec',
    height: '200px',
    width: '200px',
    position: 'absolute',
    top: '150px',
    right: '150px',
  },
  award: {
    height: '175px',
    background: 'url("app/about/images/excellence.png")',
    backgroundSize: '60% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    border: '1px solid white',
    margin: '10px',
    position: 'relative',
  },
  years: {
    position: 'absolute',
    top: '80px',
    width: '175px',
    textAlign: 'center',
    fontSize: '4rem',
    fontStyle: 'bold',
    color: 'white',
  },
  yearsText: {
    fontSize: '.9rem',
    textTransform: 'uppercase',
  },
  p: {
    marginTop: '2rem',
    fontSize: '1.25rem',
  },
};

export default function About({
  established
}) {
  const sinceYears = moment().diff(moment(established), 'years');
  return (
    <Grid container>
        <Grid item xs={12} sm={11} style={styles.about}>
          <h1>About Us</h1>
          <p style={styles.p}>
            We are committed to giving you the best experience and price in the industry.<br/><br/>
            We have been doing this for {sinceYears} years and have partnered with some of the top insurance companies in the country to ensure we can meet your personal and business needs.
            <br /><br />
            Contact your closest branch today to find out how we can save you money.
          </p>
        </Grid>
        {/* <Grid item xs={12} sm={5}>
          <div style={{ position: 'relative' }}>
            <div style={styles.main} />
            <div style={styles.excellence}>
              <div style={styles.award}>
                <div style={styles.years}>
                  {sinceYears}
                  <div style={styles.yearsText}>years of excellence</div>
                </div>
              </div>
            </div>
          </div>
        </Grid> */}
      </Grid>
  )
}
