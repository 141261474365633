import React, { Component } from 'react';
import { filter } from 'lodash';
import ReactGA from 'react-ga';
import { Dialog, DialogTitle,DialogContent, DialogActions, Snackbar, ButtonGroup, Button } from '@mui/material';
import SectionLabel from '../components/SectionLabel';
import Person from './person';
import Contact from '../contact/contact';
import { sendAgentEmail } from '../api';

//import Currysr from "./images/currysr.jpg"
import Curryjr from "./images/curryjr.jpg"
import Gamber from "./images/gamber.jpg"
import Holmes from "./images/holmes.jpg"
import Taylor from "./images/taylor.jpg"
import Weirich from "./images/weirich.jpg"
import Ramirez from "./images/ramirez.jpg"
import Merritt from "./images/merritt.jpg"
import Curt from "./images/curt.jpg"
import Peterson from "./images/peterson.jpg"
import Kira from "./images/kira.jpg"
import Myles from "./images/myles.jpg"

const team = [
  // {
  //   "key": "maxcurrysr",
  //   "name": "Max Curry, Sr.",
  //   "title": "",
  //   "branch": "Tooele Branch",
  //   "email": "hmcurry@curins.com",
  //   "phone": "435-882-1216",
  //   "photo": Currysr
  // },
  {
    "key": "maxcurryjr",
    "name": "Max Curry, Jr.",
    "title": "",
    "branch": "Tooele Branch",
    "email": "mmcurry@curins.com",
    "phone": "435-882-1216",
    "photo": Curryjr
  },
  {
    "key": "kimgamber",
    "name": "Kim Perkins",
    "title": "",
    "branch": "Tooele Branch",
    "email": "kgamber@curins.com",
    "phone": "435-882-1216",
    "photo": Gamber
  },
  {
    "key": "jeskalynholmes",
    "name": "Jeskalyn Holmes",
    "title": "",
    "branch": "Tooele Branch",
    "email": "jholmes@curins.com",
    "phone": "435-882-1216",
    "photo": Holmes
  },
  {
    "key": "alynntaylor",
    "name": "Alynn Taylor",
    "title": "",
    "branch": "Tooele Branch",
    "email": "alynn@curins.com",
    "phone": "435-882-1216",
    "photo": Taylor
  },
  {
    "key": "loriweirich",
    "name": "Lori Weirich",
    "title": "",
    "branch": "Tooele Branch",
    "email": "lori@curins.com",
    "phone": "435-882-1216",
    "photo": Weirich
  },
  {
    "key": "cindyramirez",
    "name": "Cindy Ramirez",
    "title": "",
    "branch": "Tooele Branch / Habla Español",
    "email": "cindy@curins.com",
    "phone": "435-882-1216",
    "photo": Ramirez,
    "spanish": true
  },
  {
    "key": "merrittcurry",
    "name": "Merritt Curry",
    "title": "",
    "branch": "Tooele Branch",
    "email": "merritt@curins.com",
    "phone": "435-882-1216",
    "photo": Merritt
  },
  {
    "key": "curtwestphal",
    "name": "Curt Westphal",
    "title": "",
    "branch": "Tooele Branch",
    "email": "curt@curins.com",
    "phone": "435-882-1216",
    "photo": Curt
  },
  {
    "key": "kriamunson",
    "name": "Kira Munson",
    "title": "",
    "branch": "Tooele Branch",
    "email": "curt@curins.com",
    "phone": "435-882-1216",
    "photo": Kira
  },
  {
    "key": "mylescurry",
    "name": "Myles Curry",
    "title": "",
    "branch": "Tooele Branch",
    "email": "curt@curins.com",
    "phone": "435-882-1216",
    "photo": Myles
  },
  {
    "key": "cassiewarr",
    "name": "Cassie Peterson",
    "title": "",
    "branch": "Grantsville Branch",
    "email": "cassie.warr@curins.com",
    "phone": "435-884-6913",
    "photo": Peterson
  }
]

const styles = {
  container: {
    padding: '5rem 1rem',
    backgroundColor: '#26c6da',
  },
  team: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
};

export default class Team extends Component {
  
  state = {
    filteredTeam: [...team],
    sendMessage: false,
    sendMessageTo: {},
    message: {},
    toastMessage: '',
    showToast: false,
  };

  filterTeam = (filterStr) => () => {
    /* eslint init-declarations:0, consistent-return:0*/
    let filteredTeam;
    if (filterStr.length) {
      filteredTeam = filter(team, (o) => {
        if (o.branch.includes(filterStr)) {
          return o;
        }
      });
    } else {
      filteredTeam = [...team];
    }
    this.setState({ filteredTeam });
  }

  handleOpenMessage = (person) => () => {
    ReactGA.event({
      category: 'Forms',
      action: `Opened Contact Agent Form For ${person.name}`,
    });
    this.setState({ sendMessage: true,
      sendMessageTo: person });
  }

  handleCloseMessage = () => {
    this.setState({ sendMessage: false });
  }

  handleMessageChange = (messageObject) => {
    this.setState({ message: messageObject });
  }

  handleSendMessage = () => {
    ReactGA.event({
      category: 'Forms',
      action: `Sent Contact Agent Form To ${this.state.sendMessageTo.name}`,
    });
    const email = {
      to: this.state.sendMessageTo.email,
      agentName: this.state.sendMessageTo.name,
      contactName: this.state.message.name,
      contactMethod: this.state.message.method,
      contactEmail: this.state.message.email,
      contactPhone: this.state.message.phone,
      message: this.state.message.message,
    };

    sendAgentEmail(email)
      .then((response) => {
        const message = response.data.message;
        this.setState({ showToast: true,
          toastMessage: message,
          sendMessage: false });
      })
      .catch((error) => {
        ReactGA.event({
          category: 'Error',
          action: `Error Sending Contact Agent Form To ${this.state.sendMessageTo.name}`,
        });
        const message = error.data.message;
        this.setState({ showToast: true,
          toastMessage: message });
      });
  }

  handleRequestCloseToast = () => {
    this.setState({ showToast: false,
      toastMessage: '' });
  }

  render() {
    return (
      <div style={styles.container}>
        <SectionLabel sub={'your'} main={'Agent'} />
        <ButtonGroup variant="contained" color="secondary">
          <Button onClick={this.filterTeam('')}>All</Button>
          <Button onClick={this.filterTeam('Tooele')}>Tooele</Button>
          <Button onClick={this.filterTeam('Grantsville')}>Grantsville</Button>
          <Button onClick={this.filterTeam('Habla Español')}>Habla Español</Button>
        </ButtonGroup>
        <div style={styles.team}>
          {this.state.filteredTeam.map((person, i) => (
            <Person key={i} person={person} onShowMessageForm={this.handleOpenMessage(person)} />
          ))}
        </div>
        <Dialog
          fullWidth
          maxWidth="md"
          open={this.state.sendMessage}
          >
          <DialogTitle>{this.state.sendMessageTo.name}</DialogTitle>
          <DialogContent>
            <Contact onFieldChange={this.handleMessageChange} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseMessage}
              >Cancel</Button>
            <Button
              onClick={this.handleSendMessage}
              >Submit</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={this.state.showToast}
          message={this.state.toastMessage}
          autoHideDuration={3000}
          onRequestClose={this.handleRequestCloseToast}
          />
      </div>
    );
  }
}
