import React, { Component } from 'react';
import { sortBy } from "lodash";
import SectionLabel from '../components/SectionLabel';
import Product from './product';

import allstate from "./images/allstate.png";
import acuity from "./images/acuity.png";
import nationwide from "./images/nationwide.png";
import autoowners from "./images/autoowners.png";
import progressive from "./images/progressive.png";
import cuic from "./images/cuic.png";
import falcon from "./images/falcon.svg";
import cna from "./images/cna.png";
import propeller from "./images/propeller.png"
import philadelphia from "./images/philadelphia.png"
//import unitedheritage from "./images/unitedheritage.gif";
import travelers from "./images/travelers.png";
//import bearriver from "./images/bearriver.gif";
import dairyland from "./images/dairyland.png";
import mainstreetamerica from "./images/mainstreetamerica.png";
import nationalgeneral from "./images/nationalgeneral.png";
import berkleyaspire from "./images/berkleyaspire.png";
import agraria from "./images/agraria.png";
import foremost from "./images/foremost.gif";
import hagerty from "./images/hagerty.png";
import safeco from "./images/safeco.png";
import stillwater from "./images/stillwater.png";
import libertymutual from "./images/libertymutual.png";
import unitedinsurancegroup from "./images/unitedinsurancegroup.jpg";
import markel from "./images/markel.png";
import gainsco from "./images/gainsco.jpeg";
//import sublimity from "./images/sublimity.png"
import farmersunion from "./images/farmersunion.png";
import americancollectors from "./images/americancollectors.png"
import hartford from "./images/hartford.png";

const ProductsData = sortBy([
  {
    "key": "allstate",
    "title": "Allstate",
    "logo": allstate,
    "url": "https://www.allstate.com/",
    "description": ""
  },
  {
    "key": "acuity",
    "title": "Acuity",
    "logo": acuity,
    "url": "https://www.acuity.com",
    "description": ""
  },
  {
    "key": "nationwide",
    "title": "Nationwide",
    "logo": nationwide,
    "url": "https://www.nationwide.com/",
    "description": ""
  },
  {
    "key": "autoowners",
    "title": "Auto Owners",
    "logo": autoowners,
    "url": "http://www.auto-owners.com/",
    "description": ""
  },
  {
  "key": "progressive",
  "title": "Progressive",
  "logo": progressive,
  "url": "https://www.progressive.com/",
  "description": ""
  },
  // {
  //   "key": "unitedheritage",
  //   "title": "United Heritage",
  //   "logo":unitedheritage,
  //   "url": "https://www.unitedheritage.com/",
  //   "description": ""
  // },
  {
    "key": "travelers",
    "title": "Travelers",
    "logo": travelers,
    "url": "https://www.travelers.com/",
    "description": ""
  },
  {
    "key": "cuic",
    "title": "CUIC Insurance",
    "logo": cuic,
    "url": "https://cuicauto.com/",
    "description": ""
  },
  {
    "key": "falcon",
    "title": "Falcon Insurance",
    "logo": falcon,
    "url": "https://www.falconinsgroup.com/",
    "description": ""
  },
  {
    "key": "cna",
    "title": "CNA Bonds",
    "logo": cna,
    "url": "https://www.cna.com/",
    "description": ""
  },
  {
    "key": "propeller",
    "title": "Propeller Bonds",
    "logo": propeller,
    "url": "https://www.propellerbonds.com/",
    "description": ""
  },
  {
    "key": "philadelphia",
    "title": "Philadelphia Insurance",
    "logo": philadelphia,
    "url": "https://www.phly.com/",
    "description": ""
  },
  {
    "key": "hartford",
    "title": "Hartford Insurance",
    "logo": hartford,
    "url": "https://www.thehartford.com/",
    "description": ""
  },
  // {
  //   "key": "beerriver",
  //   "title": "Beer River Mutual",
  //   "logo": bearriver,
  //   "url": "http://www.bearriveragent.com/",
  //   "description": ""
  // },
  {
    "key": "dairyland",
    "title": "Dairyland",
    "logo": dairyland,
    "url": "https://www.dairylandinsurance.com/",
    "description": ""
  },
  {
    "key": "foremost",
    "title": "Foremost",
    "logo": foremost,
    "url": "http://www.foremost.com/",
    "description": ""
  },
  {
    "key": "hagerty",
    "title": "Hagerty",
    "logo": hagerty,
    "url": "https://www.hagerty.com/",
    "description": ""
  },
  {
    "key": "safeco",
    "title": "Safeco",
    "logo": safeco,
    "url": "http://www.safeco.com/",
    "description": ""
  },
  {
    "key": "mainstreetamerica",
    "title": "Main Street America",
    "logo": mainstreetamerica,
    "url": "https://msainsurance.com/",
    "description": ""
  },
  {
    "key": "nationalgeneral",
    "title": "National General",
    "logo": nationalgeneral,
    "url": "https://nationalgeneral.com/",
    "description": ""
  },
  {
    "key": "agraria",
    "title": "Agraria",
    "logo": agraria,
    "url": "https://agraria.com/",
    "description": ""
  },
  {
    "key": "berkleyaspire",
    "title": "Berkley Aspire",
    "logo": berkleyaspire,
    "url": "https://www.berkleyaspire.com/",
    "description": ""
  },
  {
    "key": "stillwater",
    "title": "Stillwater",
    "logo": stillwater,
    "url": "https://stillwaterinsurance.com",
    "description": ""
  },
  {
    "key": "libertymutual",
    "title": "Liberty Mutual",
    "logo": libertymutual,
    "url": "https://www.libertymutual.com/",
    "description": ""
  },
  {
    "key": "unitedinsurancegroup",
    "title": "United Insurance Group",
    "logo": unitedinsurancegroup,
    "url": "https://www.uuinsurance.com/",
    "description": ""
  },
  {
    "key": "markel",
    "title": "Markel",
    "logo": markel,
    "url": "https://www.markelinsurance.com",
    "description": ""
  },
  {
    "key": "gainsco",
    "title": "Gainsco",
    "logo":gainsco,
    "url": "https://www.gainsco.com",
    "description": ""
  },
  // {
  //   "key": "sublimity",
  //   "title": "Sublimity",
  //   "logo": sublimity,
  //   "url": "https://www.sublimityins.com/",
  //   "description": ""
  // },
  {
    "key": "farmersunion",
    "title": "Farmers Union",
    "logo": farmersunion,
    "url": "https://www.farmersunioninsurance.com/",
    "description": ""
  },
  {
    "key": "americancollectors",
    "title": "American Collectors",
    "logo": americancollectors,
    "url": "https://americancollectors.com/",
    "description": ""
  }
], "title")



const styles = {
  container: {
    margin: '1rem 0 0 0',
    padding: '5rem 1rem',
    backgroundColor: '#e7eff1',
  },
  h2: {
    fontWeight: 300,
    fontSize: '1.5rem',
  },
  techs: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
};


export default class Products extends Component {
  
  state = { 
    products: [...ProductsData] 
  };

  render() {
    return (
      <div style={styles.container}>
        <SectionLabel sub={'our'} main={'partners'} />
        <div style={styles.techs}>
          {this.state.products.map((product, i) => (
            <Product key={i} product={product} />
          ))}
        </div>
      </div>
    );
  }
}
