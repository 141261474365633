import React, { Component } from 'react';
import {Grid, TextField, MenuItem} from '@mui/material';

export default class Quote extends Component {
  state = {
    name: null,
    email: null,
    phone: null,
    method: 'Phone',
    service: null,
    message: null,
  };

  handleChange = (field) => (event) => {
    this.setState({ [field]: event.target.value }, () => {
      this.props.onFieldChange(this.state);
    });
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Name"
            variant="standard"
            fullWidth
            onChange={this.handleChange('name')}
            />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            variant="standard"
            fullWidth
            onChange={this.handleChange('email')}
            />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Phone Number"
            variant="standard"
            fullWidth
            onChange={this.handleChange('phone')}
            />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            label="Preferred Contact Method"
            variant="standard"
            fullWidth
            value={this.state.preferredContactMethod}
            onChange={this.handleChange('preferredContactMethod')}
            >

            <MenuItem value={'Phone'}>Phone</MenuItem>
            <MenuItem value={'Email'}>Email</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            variant="standard"
            label="Quote On"
            fullWidth
            value={this.state.service}
            onChange={this.handleChange('service')}
            >
            {this.props.services.map((service, index) => <MenuItem key={index} value={service}>{service}</MenuItem>)}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Message"
            variant="standard"
            fullWidth
            multiLine
            rowsMax={2}
            onChange={this.handleChange('message')}
            />
        </Grid>
      </Grid>
    );
  }
}
