/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'proptypes';

const styles = {
  tech: {
    width: '15rem',
    border: '1px solid lightgray',
    borderRadius: '1rem',
    margin: '1rem',
    padding: '1rem',
    backgroundColor: 'white',
  },
  product: {
    width: '15rem',
    margin: '1rem',
  },
  logo: {
    height: '5rem',
    maxWidth: '15rem',
    float: 'right',
    margin: '0 auto',
  },
  h3: {
    fontSize: '1.5rem',
    margin: '0 0 2rem 0',
  },
};

export default class Product extends Component {
  render() {
    return (
      <div style={styles.product}>
        <a href={this.props.product.url} target="_blank" rel="noreferrer">
          <img style={styles.logo} src={this.props.product.logo} />
        </a>
      </div>
    );
  }
}

Product.propTypes = {
  product: PropTypes.object.isRequired,
};
