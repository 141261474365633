import React, { Component } from 'react';
import moment from 'moment';
import {Grid} from '@mui/material'

const currentYear = moment().year();

const styles = {
  footer: {
    padding: '0.5rem',
    fontSize: '1rem',
    backgroundColor: '#1f1f1f',
    textAlign: 'center',
    color: 'white',
  },
};

export default class Footer extends Component {
  render() {
    return (
      <footer style={styles.footer}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <h3 style={{ color: '#5ac3f0' }}>Tooele Office</h3>
            <span style={{ color: '#ccc' }}>
              <address>
          533 North Main<br />
          Tooele, Utah 84074
          </address>
              <br />
          Phone: <a
            href="tel:435-882-1216" style={{ color: '#ccc',
              textDecoration: 'none' }}
                                    >(435) 882-1216</a><br />
          Fax: (435) 882-1220<br />
            </span>
          </Grid>
          <Grid item xs={4}>
            <h3 style={{ color: '#e6e6fa' }}>Our Hours</h3>
            <span style={{ color: '#ccc' }}>
              <address>
              Monday - Friday<br /><br />
              9:00am - 5:30pm<br />
              closed 12:30pm - 1:30pm for lunch
              </address>
            </span>
          </Grid>
          <Grid item xs={4}>
            <h3 style={{ color: '#55dabf' }}>Grantsville Office</h3>
            <span style={{ color: '#ccc' }}>
              <address>
          183 East Main<br />
          Grantsville, Utah 84029
          </address>
              <br />
          Phone: <a
            href="tel:435-884-6913" style={{ color: '#ccc',
              textDecoration: 'none' }}
                                    >(435) 884-6913</a><br />
          Fax: (435) 884-4436<br />
            </span>
          </Grid>
        </Grid>
        <div
          style={{ marginTop: '25px',
            color: '#666',
            fontSize: '.75rem' }}
          >
        &copy;{currentYear} Curry Insurance, LLC <br /> Created by <a
          href="mailto:petesashworth@gmail.com" style={{ textDecoration: 'none',
            color: '#666' }}
                                                >AshworthHub, LLC</a>
        </div>
      </footer>
    );
  }
}
